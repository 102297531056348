$primary: #c2bc37;
$secondary: #3b3b3b;
$info: #757575;
$warning: #ed6c02;

:export {
  primary: $primary;
  secondary: $secondary;
  info: $info;
  warning: $warning
}

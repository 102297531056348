@import './palette.module.scss';

$row-cell-width: 105px;
$collapsible-cell-width: 260px;
$change-cell-width: 140px;

// Override CSS from package ("!important" must be used only if the CSS class is injected after this file)
.react-datepicker-popper {
  z-index: 50 !important;
}

.MuiAutocomplete-option[aria-selected='true'] {
  background-color: rgba(194, 188, 55, 0.5) !important;

  &.Mui-focused {
    background-color: rgba(194, 188, 55, 0.6) !important;
  }
}

// CSS for the app
body {
  background-color: #e5e5e5 !important;
}

.header {
  color: #ebebeb;
  background-color: #242424;
}

.main-container {
  padding: 0px 20px 20px;

  &.no-access {
    padding-top: 60px;
    display: flex;
    justify-content: center;
  }
}

.overflow-container {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  max-height: calc(100vh - 64px - 150px);
  padding: 0px 5px 5px;

  & .sticky-column-container {
    min-width: fit-content;

    & .sticky-corner {
      position: sticky;
      left: -5px;
      z-index: 30;
    }

    & .sticky-column {
      position: sticky;
      left: -5px;
      z-index: 10;
    }
  }

  & .sticky-header {
    position: sticky;
    top: -12px;
    z-index: 20;
  }

  & .collapse-container {
    min-height: auto;
  }
}

.section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  margin-top: 20px;
  padding: 6px 16px;
  color: #ebebeb;
  background-color: $secondary;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 1px 5px rgba(0, 0, 0, 0.12);

  & .MuiIconButton-root {
    padding: 6px;
  }
}

.input {
  & .MuiOutlinedInput-root {
    margin-top: 5px;
    background-color: #ededed;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14),
      0px 1px 5px rgba(0, 0, 0, 0.12);

    &:hover {
      background-color: #d9d9d9;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2),
        0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);
    }

    & fieldset {
      border: none;
    }
  }
}

.paper {
  margin-top: 12px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 1px 5px rgba(0, 0, 0, 0.12);

  &.filter-paper {
    padding: 12px;
  }

  & .paper-action {
    margin-top: 4px;
  }

  &.expanded-paper {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  &.hotel-paper {
    margin-top: 0px;
    background-color: #f6f6f6;
    border-radius: 0px;
  }

  &.day-paper {
    margin-top: 0px;
    background-color: #ededed;
    border-radius: 0px;
  }
}

.table {
  color: inherit;

  &:not(:last-child) {
    margin-right: 20px;
  }

  &.corner-table {
    margin-right: 0px;
    min-width: $collapsible-cell-width;
    border-right: 1px solid #606060;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  &.collapsible-table {
    margin-right: 0px;
    min-width: $collapsible-cell-width;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  &.room-nights-table {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    min-width: calc($change-cell-width + $row-cell-width * 15);
  }

  &.adr-table {
    min-width: calc($change-cell-width + $row-cell-width * 12);
  }

  &.room-revenue-table {
    min-width: calc($row-cell-width * 9);
  }

  &.non-room-revenue-table {
    min-width: calc($change-cell-width + $row-cell-width * 9);
  }
}

.MuiTableCell-root {
  color: inherit;
  padding: 4px 6px;
  line-height: 1.5rem;
  min-width: $row-cell-width;
  max-width: $row-cell-width;

  &:not(:last-child) {
    border-right: 1px solid #e0e0e0;
  }

  &.loading-cell {
    display: inline-flex;
    justify-content: center;
  }

  &.numeric-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.clickable {
      cursor: pointer;
    }
  }

  &.collapsible-loading-cell {
    padding: 4px 16px;
    min-width: $collapsible-cell-width;
    max-width: $collapsible-cell-width;
    border-right: 1px solid #606060;
  }

  &.collapsible-cell {
    padding: 4px 16px;
    min-width: $collapsible-cell-width;
    max-width: $collapsible-cell-width;
    border-right: 1px solid #606060;
    display: flex;
    align-items: center;

    & .collapsible-total {
      display: flex;
      align-items: center;
      flex-grow: 1;
    }

    & .collapsible-segment {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-grow: 1;
    }

    & .collapsible-hotel {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      min-width: 0;
    }

    & .collapsible-day {
      display: flex;
      justify-content: flex-end;
      flex-grow: 1;
    }

    & .MuiIconButton-root {
      margin-left: 10px;
      padding: 0px;
    }

    & .collapsible-hotel-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & .collapsible-icon {
      margin-right: 16px;
    }
  }

  &.change-cell {
    min-width: $change-cell-width;
    max-width: $change-cell-width;
    padding: 4px 4px;

    & .MuiLoadingButton-root {
      padding: 0px;
      min-width: 24px;
      height: 24px;

      & .MuiButton-endIcon {
        margin: 0px;
      }
    }

    & .MuiOutlinedInput-root {
      padding: 0px;
      margin-top: 0px;
      height: 24px;
      font-size: inherit;
      box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.2),
        inset 0px 2px 2px rgba(0, 0, 0, 0.14),
        inset 0px 1px 5px rgba(0, 0, 0, 0.12);

      & .MuiOutlinedInput-input {
        padding: 0px;
        height: 24px;
        text-align: center;
      }
    }
  }
}

.switch-header {
  & .MuiSwitch-switchBase {
    &:not(.Mui-checked) {
      & .MuiSwitch-thumb {
        background-color: $info;
      }

      & + .MuiSwitch-track {
        background-color: $info;
      }
    }
  }
}

.circular-progress-lock-modal {
  display: flex;
  justify-content: center;
}

.disabled {
  color: rgba(0, 0, 0, 0.25);

  & .exceeded-occupancy-rate {
    color: inherit;
  }

  & .occupancy-rate {
    color: inherit;
  }
}

.exceeded-occupancy-rate {
  color: red;
}

.occupancy-rate {
  color: rgba(0, 0, 0, 0.4);
}

.global-backdrop-loader {
  z-index: 1250;
}

.no-results-data {
  position: absolute;
  width: fit-content;
  margin-top: 140px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.popper {
  z-index: 50;

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: calc(50% - 7px);
    left: -7px;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 7px solid $secondary;
  }
}

.calculate {
  padding: 16px;
  background-color: $secondary;
  border-radius: 4px;
  color: #ebebeb;

  .calculate-input {
    & .MuiOutlinedInput-root {
      padding: 0px;
      margin-top: 0px;
      height: 24px;
      width: 130px;
      font-size: 12px;
      box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.2),
        inset 0px 2px 2px rgba(0, 0, 0, 0.14),
        inset 0px 1px 5px rgba(0, 0, 0, 0.12);

      & .MuiOutlinedInput-input {
        padding: 0px;
        height: 24px;
        text-align: center;

        &.Mui-disabled {
          -webkit-text-fill-color: rgba(0, 0, 0, 0.7);
        }
      }

      &.Mui-disabled {
        background-color: #c0c0c0;
      }
    }
  }
}
